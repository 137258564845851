import React, { ReactElement } from "react";
import WhyUsImg from "../icons/img2";
export default function WhyQuoto(): ReactElement {
    return (
        <section className="flex lg:flex-row flex-col lg:gap-32 gap-10 lg:my-24 my-12 ">
            <div className="">
                <h2 className="font-bold sm:text-3xl text-xl leading-7 sm:leading-[45px] ">
                    Why Choose QuoteSpot.com?
                </h2>
                <p className="font-light mt-4 lg:mt-7 lg:text-lg ">
                    In today's fast-paced business landscape, we recognize the
                    challenges you face. That's why we've created QuoteSpot.com,
                    a platform designed to simplify the process of obtaining
                    quotes for essential products and services. With just a few
                    clicks, you can effortlessly compare quotes from a diverse
                    range of providers and make well-informed decisions that
                    benefit your business. This saves you precious time and
                    ensures you secure the best deals available.
                </p>
                <p className="font-light mt-4 lg:mt-7 lg:text-lg ">
                    Our dedicated team of experts works tirelessly to deliver
                    accurate and up-to-date information, empowering you with the
                    knowledge needed to make confident choices. The best part?
                    Our service is entirely free, eliminating any risk or
                    obligation on your part. Opt for QuoteSpot.com as your go-to
                    resource for all your business needs, and relish the
                    convenience of having everything at your fingertips.
                </p>
            </div>
            <div className="mx-auto lg:ml-0  lg:mt-5 ">
                <WhyUsImg />
            </div>
        </section>
    );
}
